import React, { useState } from "react";
import { useNavigate  } from "react-router-dom";
import { Helmet } from 'react-helmet';


export const Register = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [name, setName] = useState('');
    const [register, setRegister] = useState(false);

    const history = useNavigate();
    const login = () => {
        history("/login");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('http://localhost:4000/register', {
        method: 'POST',
        body: JSON.stringify({
            email: email,
            password: pass,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "credentials": 'include'
        },
        })
        .then((response) => response.json())
        .then((json) => {
            console.log(json)
            if (json.message === "User Created Successfully") {
                setRegister(true);
                login()
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (

        <html>
            <body>

            <Helmet>
          <title>{"Konoashi's Proxy - Login"}</title>
        </Helmet>

                <div className="auth-form-container">
            <h2>Register</h2>
        <form className="register-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Full name</label>
            <input value={name} name="name" onChange={(e) => setName(e.target.value)} id="name" placeholder="full Name" />
            <label htmlFor="email">email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)}type="email" placeholder="youremail@gmail.com" id="email" name="email" />
            <label htmlFor="password">password</label>
            <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" />
            <button type="submit">Register</button>
            {register ? (
          <p className="text-success">You Are Registered Successfully</p>
        ) : (
          <p className="text-danger">You Are Not Registered</p>
        )}
        </form>
        <button className="link-btn" onClick={login}>Already have an account? Login here.</button>
    </div>
            </body>

            
        </html>
        
    )
}
