import React, { useEffect, useState,  } from "react";
import Cookies from "universal-cookie";
import { Helmet } from 'react-helmet';
const cookies = new Cookies();

export default function AuthComponent() {
    const [message, setMessage] = useState("");

useEffect(() => {
    // set configurations for the API call here
    const token = cookies.get("TOKEN");

     fetch('http://localhost:4000/auth-endpoint', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "credentials": 'include',
            "Authorization": `Bearer ${token}`
        },
    })
        .then((response) => response.json())
        .then((json) => {
                setMessage(json.message) 
                if (json.message === "authorized") {
                } else {
                    window.location.href = "/login"
                }
        })
        .catch(error => {
            console.log(error)
        })
  }, []);


  return (
    <html>

        <Helmet>
            <title>{"Konoashi's Proxy - Dashboard"}</title>
        </Helmet>

        <body>
            <div>
                <h1 className="text-center">{message}</h1>
            </div>
        </body>
    </html>
    
  );
}