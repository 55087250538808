import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Helmet } from 'react-helmet';
import "../css/Login.css"
const cookies = new Cookies();

export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [login, setLogin] = useState(false);

    const history = useNavigate();
    const register = () => {
        history("/register");
    }

    useEffect(() => {
        // Redirige directement sur le dashboard si l'user est authentifié avec les cookies et que le token est correct
        const token = cookies.get("TOKEN");
    
        fetch('http://localhost:4000/auth-endpoint', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "credentials": 'include',
            "Authorization": `Bearer ${token}`
        },
    })
        .then((response) => response.json())
        .then((json) => {
                if (json.message === "authorized") {
                    window.location.href = "/dashboard"
                } 
        })
        .catch(error => {
            console.log(error)
        })
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(props)


        fetch('http://localhost:4000/login', {
        method: 'POST',
        body: JSON.stringify({
            email: email,
            password: pass,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "credentials": 'include'
    
        },
        })
        .then((response) => response.json())
        .then((json) => {

            if (json.message === "Login Successful") {
                setLogin(true);
                cookies.set("TOKEN", json.token, {
                    path: "/",
                  });
                  window.location.href = "/dashboard"
            } else if(json.message === "Email not found") {
                let notif = document.getElementById('success-notif')
                notif.setAttribute('class', 'nortification animateOpen')
                notif.innerHTML = "There's no account associated to that e-mail"
                setTimeout(function(){
                notif.setAttribute('class', 'nortification');
                },3000);
            } else if(json.message === "Passwords does not match") {
                let notif = document.getElementById('success-notif')
                notif.setAttribute('class', 'nortification animateOpen')
                notif.innerHTML = "Passwords does not match"
                setTimeout(function(){
                notif.setAttribute('class', 'nortification');
                },3000);
            }

        })
        .catch(error => {
            console.log(error)
        })


    }

    return (
        

        <html>

        <Helmet>
          <title>{"Konoashi's Proxy - Login"}</title>
        </Helmet>

            <body>
            <div class="box-notif">
            <span id="success-notif" class="nortification">Text</span>
            </div>

        <div className="auth-form-container">
                <h2>Login</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="email">email</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="youremail@gmail.com" id="email" name="email" />
                    <label htmlFor="password">password</label>
                    <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" />
                    <button type="submit">Log In</button>
                    {login ? (
                        <p className="text-success">You Are Logged in Successfully</p>
                    ) : (
                        <p className="text-danger">You Are Not Logged in</p>
                    )}
                </form>
                <button className="link-btn" onClick={register}>Don't have an account? Register here.</button>
            </div>

            
            
            </body>
            </html>
    )
}