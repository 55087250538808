import './Components/css/App.css';
import { Login } from "./Components/Routes/Login";
import { Register } from "./Components/Routes/Register";
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import FreeComponent from "./Components/Routes/FreeComponent";
import AuthComponent from "./Components/Routes/AuthComponent";
import background from "./img/background.png";
import Private from './Components/Parts/Private.tsx';
//import Navbar from './Components/Parts/Navbar.jsx';

function App() {

  return (

    <div>
    <div style={{ backgroundImage: `url(${background})`,  'backgroundSize': 'cover' }} className="App">
      
    <div>
      </div>
        <BrowserRouter>
        <Routes>
        
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/" element={<FreeComponent/>} />
          <Route path="/dashboard" element = {Private(<AuthComponent />)}/>
          
        </Routes>
        </BrowserRouter>
        
    </div>
    </div>
    
    
    
  );
}

export default App;
