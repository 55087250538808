import Cookies from "universal-cookie";
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import React from "react";

const cookies = new Cookies();

function render(c: JSX.Element) {
  return c;
}

const Private = (Component: JSX.Element) => {
  const [hasSession, setHasSession] = useState<boolean>(true);

  useEffect(() => {
    (async function () {
      const sessionStatus = await checkLoginSession();
      console.log(sessionStatus)
      setHasSession(Boolean(sessionStatus));
    })();
  }, [hasSession, Component]);
  

  return hasSession ? render(Component) : <Navigate to="/login" />;
};
async function checkLoginSession() {
  const token = cookies.get("TOKEN");

  if(token !== undefined) {
    return true
  } else {
    return false
  }

}

export default Private;