// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-notif{
    width:100%;
    position:relative;
  }
  
  .nortification{
      display: block;
      font-size: 17px;
      width: 600px;
      padding: 12px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -305px;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: #a90000;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      /* box-shadow: 0 3px 0 rgb(0 0 0 / 20%); */
      margin-top: -420px;
      
  }
  
  .animateOpen{
      -webkit-animation:moveOpen 3s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
  }
  /* Safari and Chrome */
  @keyframes moveOpen 
      {
    from {-webkit-transform: translate(0,-350px);}
    10% {-webkit-transform: translate(0,220px);}
    12% {-webkit-transform: translate(0,222px);}
    16% {-webkit-transform: translate(0,220px);}
    80% {-webkit-transform: translate(0,220px);}
    85% {-webkit-transform: translate(0,225px);}
    to {-webkit-transform: translate(0,-350px);}
  }`, "",{"version":3,"sources":["webpack://./src/Components/css/Login.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;EACnB;;EAEA;MACI,cAAc;MACd,eAAe;MACf,YAAY;MACZ,aAAa;MACb,kBAAkB;MAClB,MAAM;MACN,SAAS;MACT,mBAAmB;MACnB,sBAAsB;MACtB,mBAAmB;MACnB,yBAAyB;MACzB,cAAc;MACd,iBAAiB;MACjB,kBAAkB;MAClB,0CAA0C;MAC1C,kBAAkB;;EAEtB;;EAEA;MACI,6BAA6B;IAC/B,oCAAoC;IACpC,qCAAqC;EACvC;EACA,sBAAsB;EACtB;;IAEE,MAAM,sCAAsC,CAAC;IAC7C,KAAK,qCAAqC,CAAC;IAC3C,KAAK,qCAAqC,CAAC;IAC3C,KAAK,qCAAqC,CAAC;IAC3C,KAAK,qCAAqC,CAAC;IAC3C,KAAK,qCAAqC,CAAC;IAC3C,IAAI,sCAAsC,CAAC;EAC7C","sourcesContent":[".box-notif{\n    width:100%;\n    position:relative;\n  }\n  \n  .nortification{\n      display: block;\n      font-size: 17px;\n      width: 600px;\n      padding: 12px;\n      position: absolute;\n      top: 0;\n      left: 50%;\n      margin-left: -305px;\n      box-sizing: border-box;\n      border-radius: 15px;\n      background-color: #a90000;\n      color: #ffffff;\n      font-weight: bold;\n      text-align: center;\n      /* box-shadow: 0 3px 0 rgb(0 0 0 / 20%); */\n      margin-top: -420px;\n      \n  }\n  \n  .animateOpen{\n      -webkit-animation:moveOpen 3s;\n    -webkit-animation-iteration-count: 1;\n    -webkit-animation-fill-mode: forwards;\n  }\n  /* Safari and Chrome */\n  @keyframes moveOpen \n      {\n    from {-webkit-transform: translate(0,-350px);}\n    10% {-webkit-transform: translate(0,220px);}\n    12% {-webkit-transform: translate(0,222px);}\n    16% {-webkit-transform: translate(0,220px);}\n    80% {-webkit-transform: translate(0,220px);}\n    85% {-webkit-transform: translate(0,225px);}\n    to {-webkit-transform: translate(0,-350px);}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
