// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #1d1d1ffb, #1d1d1ffb 48%, #1d1d1ffb);
  
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border: 1px solid white;
    background-color: rgba(0,0,0, 0.2);
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #000000;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Components/css/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,6EAA6E;;AAE/E;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;IACb,kCAA0B;YAA1B,0BAA0B;IAC1B,uBAAuB;IACvB,kCAAkC;IAClC,mBAAmB;IACnB,cAAc;EAChB;AACF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  min-height: 100vh;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  background-image: linear-gradient(79deg, #1d1d1ffb, #1d1d1ffb 48%, #1d1d1ffb);\n  \n}\n\n.auth-form-container, .login-form, .register-form {\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (min-width: 600px) {\n  .auth-form-container {\n    padding: 5rem;\n    backdrop-filter: blur(4px);\n    border: 1px solid white;\n    background-color: rgba(0,0,0, 0.2);\n    border-radius: 10px;\n    margin: 0.5rem;\n  }\n}\n\nlabel {\n  text-align: left;\n  padding: 0.25rem 0;\n}\n\ninput {\n  margin: 0.5rem 0;\n  padding: 1rem;\n  border: none;\n  border-radius: 10px;\n}\n\nbutton {\n  border: none;\n  padding: 20px;\n  border-radius: 10px;\n  cursor: pointer;\n  color: #000000;\n}\n\n.link-btn {\n  background: none;\n  color: white;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
